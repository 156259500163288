<template>
    <div style="min-height: calc(100vh - 158px);">
        <div style="min-height: calc(100vh - 168px);" class="card">
            <div style="background: #DBE7F2" class="pb-1">
                <TitleButton
                    class="px-2 pt-2"
                    :showBtn="false"
                    title="Balance Sheet"
                />
                <div class="px-2">
                    <DateQuerySetter
                        btnTitle="Go"
                        @onClickGo="onClickGo"
                    >
                      <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label for="colFormLabel" class="col-form-label">Business</label>
                        <v-select
                            placeholder="Select Business"
                            class="w-100"
                            :options="businesses"
                            label="name"
                            :reduce="name => name.id"
                            v-model="business_id"
                        />
                      </div>
                    </DateQuerySetter>
                </div>
            </div>

            <div class="mt-2"></div>
            <div class="">
              <button class="btn btn-success float-end me-4" @click="exportBalanceSheet">Export</button>
            </div>
            <div :style="{opacity: accountingLoading ? .5 : 1, maxWidth: '640px', width: '100%', zIndex: 10}" class="d-flex justify-content-between bordered bg-white sticky py-1 px-2 border-t border-b mx-auto">
                <strong class="font-medium-2">Particulars</strong>
                <strong class="font-medium-2">Amount</strong>
            </div>

            <div :style="{opacity: accountingLoading ? .5 : 1, maxWidth: '640px', width: '100%'}" class="px-2 mt-1 mx-auto">
                <!-- asset -->
                <span> Assets</span>
                <div class="card">
                    <BalanceSheetTree
                        v-for="(assetItem, i) in asset"
                        :key="i"
                        :node="assetItem"
                    />
                    <div class="d-flex justify-content-between border-b-2-dark node-hover node-hover">
                        <strong>TOTAL ASSETS</strong>
                        <strong>Tk. {{ commaFormat(getTotal(asset)) }}</strong>
                    </div>
                </div>

                <!-- asset -->
                <!-- equity -->
                <span> Equity</span>
                <div class="card">
                    <BalanceSheetTree
                        v-for="(equityItem, i) in equity"
                        :key="i"
                        :node="equityItem"
                    />
                    <div class="d-flex justify-content-between border-b-2-dark node-hover">
                        <strong>TOTAL EQUITY</strong>
                        <strong>Tk. {{ commaFormat(getTotal(equity)) }}</strong>
                    </div>
                </div>
                <!-- equity -->
                <!-- liability -->
                <span> Liability</span>
                <div class="card">
                    <BalanceSheetTree
                        v-for="(liabilityItem, i) in liability"
                        :key="i"
                        :node="liabilityItem"
                    />
                    <div class="d-flex justify-content-between border-b-2-dark node-hover">
                        <strong>TOTAL LIABILITIES</strong>
                        <strong>Tk. {{ commaFormat(getTotal(liability)) }}</strong>
                    </div>
                    <div class="d-flex justify-content-between border-b-2-dark node-hover">
                        <strong>TOTAL LIABILITIES AND EQUITY</strong>
                        <strong>Tk. {{ commaFormat(getTotal(liability) + getTotal(equity)) }}</strong>
                    </div>
                </div>
                <!-- liability -->
            </div>
        </div>
        <Loader v-if="accountingLoading"/>
    </div>
</template>

<script>
import handleAccounting from '@/services/modules/accounting'
import figureFormatter from '@/services/utils/figureFormatter'
import { inject } from 'vue'

import TitleButton from '@/components/atom/TitleButton'
import DateQuerySetter from '@/components/atom/DateQuerySetter'
import BalanceSheetTree from '@/components/molecule/company/balance-sheet/BalanceSheetTree'
import Loader from '@/components/atom/LoaderComponent'
import handleCBusinesses from '@/services/modules/businesses'

export default {
    name: 'BalanceSheet',

    components: {
        TitleButton,
        DateQuerySetter,
        BalanceSheetTree,
        Loader
    },

    data: () => ({
        asset: [],
        equity: [],
        liability: [],
        balanceSheetData: [],
        businesses : [],
        business_id: '',
    }),

    computed: {
        companyId() {
            return this.$route.params.companyId
        },
        start() {
            return this.$route.query.start
        },
        end() {
            return this.$route.query.end
        },
        token() {
          return localStorage.getItem('token')
        }
    },

    methods: {
      exportBalanceSheet() {
        let url = `${process.env.VUE_APP_BASE_URL}/export/accounting/balance-sheet` + this.getQuery() + `&_token=${this.token}`;
        let a = document.createElement("a")
        a.setAttribute("href", url)
        a.click()
      },
        getTotal (data) {
            let total = 0
            data.map(i => {
                total += i.total
            })
            return total
        },
        onClickGo() {
            this.getDateWiseBalanceSheet(this.getQuery())
        },
        getQuery () {
            let query = '?company_id=' + this.companyId
            if(!this.start && !this.end) return query
            if(this.start) query += '&start_date='+this.start
            if(this.end) query += '&end_date='+this.end
            if(this.business_id != null) query += `&business_id=${this.business_id}`
            // query += '&offset='+this.offset
            return query
        },

        async getDateWiseBalanceSheet(query = '') {
            try {
                this.accountingLoading = true
                let res = await this.getBalanceSheet(query)
                if(!res.status) {
                    this.showError(res.response.message)
                }
                if(res.status) {
                    this.balanceSheetData = res.data
                    this.asset = res.data.asset ? res.data.asset : []
                    this.equity = res.data.equity ? res.data.equity : []
                    this.liability = res.data.liability ? res.data.liability : []
                }
            } catch (err) {
                if(!err.response) {
                    this.showError('Something is wrong. Check your connectivity!!')
                }
                if(err.response?.data) {
                    this.showError(err.response.data.message)
                    return
                }
            } finally {
                this.accountingLoading = false
            }
        },
      async getBusinesses() {
        try {
          let companyId = this.$route.params.companyId
          let res       = await this.fetchBusinessList('?company_id=' + companyId)

          if (!res.status) {
          }
          if (res.status) {
            this.businesses = res.data;
          }

        } catch (err) {
          if (!err.response) {
            this.showError('Something is wrong. Check your connectivity!!')
          }
          if (err.response) {
            this.showError(err.response.message)
          }
        } finally {
          this.loading = false
        }

      },
    },

    setup() {
        const showError =  inject('showError');
        const showSuccess =  inject('showSuccess');
        // const store = useStore()

        const {
            getBalanceSheet,
            accountingLoading
        } = handleAccounting()

        const {commaFormat} = figureFormatter ()
        const {fetchBusinessList} = handleCBusinesses ()

        return {
            getBalanceSheet,
            fetchBusinessList,
            accountingLoading,
            commaFormat,
            showError,
            showSuccess
        }
    },

    mounted () {
        this.getDateWiseBalanceSheet(this.getQuery())
        this.getBusinesses()
    }

}
</script>

<style scoped>
span {
    font-size:1.2em;
}
.border-t {
    border-top: 1px solid #ebe9f1;
}
 .border-b {
    border-bottom: 1px solid #ebe9f1;
 }
</style>
